:root {
  font-size: 60%;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100vh;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

body,
button,
textarea{
  font: 500 1.6rem Acme;
}

.container {
  height: 100%;
  width: 100%;
  max-width: 700px;
  background: #7EC9F3;
}

@media (min-width: 700px) {
  :root{
    font-size: 62.5%;
    
  }

}