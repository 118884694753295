#page-contact{
  width: 100vw;
  height: 100vh;
}

#page-contact main {
  margin: 3.2rem auto;
  width: 90%;
  height: 60%;
}

@media (min-width: 700px) {
  #page-contact {
   max-width: 100%;
  }

  #page-contact main {
    padding: 3.2rem 0;
    max-width: 740px;
    margin: 2 auto;
  }
}
